<template lang="html">
  <IxModuleRoot
    :translation-namespaces="['powerBi', 'Common.SR_Common']"
    :module="module"
    :module-path="['powerBi']"
    :setup="loadPrerequisiteData"
    route="/PowerBi"
  >
    <div class="power-bi-root">
      <router-view />
    </div>

    <TranslationControls />
  </IxModuleRoot>
</template>

<script>
import {mapActions} from 'vuex'
import IxModuleRoot from '@components/IxModuleRoot'
import TranslationControls from '@components/translation/TranslationControls'

import SelfDestruct from '@mixins/SelfDestruct'

export default {
  components: {
    IxModuleRoot,
    TranslationControls
  },
  mixins: [
    SelfDestruct
  ],
  computed: {
    module () {
      return () => import('@store/modules/power-bi')
    }
  },
  methods: {
    ...mapActions('powerBi', [
      'loadPrerequisiteData'
    ])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .power-bi-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
