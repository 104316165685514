import VueRouter from 'vue-router'

import store from '@store'

const getReport = route => {
  const {workspaceId, reportId} = route.params
  const {workspaces} = store.state.powerBi

  const workspace = workspaces.find(x => x.id === workspaceId)
  const report = workspace.reports.find(x => x.id === reportId)

  return {
    report
  }
}

export default new VueRouter({
  mode: 'history',
  base: '/PowerBi',
  routes: [
    {
      path: '/',
      name: 'workspaces',
      component: () => import('./views/WorkspaceList')
    },
    {
      path: '/workspaces/:workspaceId',
      name: 'workspace',
      component: () => import('./views/ReportList'),
      props: true
    },
    {
      path: '/workspaces/:workspaceId/reports/:reportId',
      name: 'report',
      component: () => import('./views/EmbeddedReport'),
      props: getReport
    },
    {
      path: '/workspaces/:workspaceId/dashboards/:reportId',
      name: 'dashboard',
      component: () => import('./views/EmbeddedDashboard'),
      props: getReport
    }
  ]
})
